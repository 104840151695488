(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/oddset/analytics/assets/javascripts/search-events.js') >= 0) return;  svs.modules.push('/components/oddset/analytics/assets/javascripts/search-events.js');
"use strict";


const oddsetTrackingLabel = 'oddset';
const onSearchQueryResolved = search => {
  svs.components.analytics.trackSearch(search.query, oddsetTrackingLabel);
};
setGlobal('svs.components.oddset.analytics', {
  onSearchQueryResolved
});

 })(window);