(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/oddset/analytics/assets/javascripts/betslip-events.js') >= 0) return;  svs.modules.push('/components/oddset/analytics/assets/javascripts/betslip-events.js');
"use strict";

var _svs$core$config$data, _svs$core$config$data2;

const isSelfService = (_svs$core$config$data = (_svs$core$config$data2 = svs.core.config.data.svsconfig) === null || _svs$core$config$data2 === void 0 ? void 0 : _svs$core$config$data2.isSelfService) !== null && _svs$core$config$data !== void 0 ? _svs$core$config$data : false;
const oddsetTrackingLabel = 'oddset';
const buildTrackingActionFromOutcome = outcome => {
  const {
    eventId,
    eventName,
    league,
    live,
    market,
    region,
    sport,
    betbuilder
  } = outcome.data;
  return "eventId=".concat(eventId, ",eventName=").concat(eventName, ",league=").concat(league, ",live=").concat(live, ",market=").concat(market, ",region=").concat(region, ",sport=").concat(sport, ",betbuilder=").concat(betbuilder);
};
const onBetReceiptAction = data => {
  let analyticsAction;
  switch (data === null || data === void 0 ? void 0 : data.action) {
    case 'clearSelections':
      analyticsAction = 'Continue';
      break;
    case 'keepSelections':
      analyticsAction = 'Save';
      break;
    default:
      analyticsAction = 'Unknown';
      break;
  }
  if (!isSelfService) {
    svs.components.analytics.trackEvent({
      category: 'onBetReceiptAction',
      action: analyticsAction,
      opt_label: 'Oddset'
    });
  }
};
const onClearBetslip = () => {
  svs.components.analytics.trackEvent({
    category: 'Clear betslip',
    opt_label: oddsetTrackingLabel
  });
};
const onAddOutcomeToBetslip = outcome => {
  svs.components.analytics.trackEvent({
    category: 'Add to betslip',
    action: buildTrackingActionFromOutcome(outcome),
    opt_label: oddsetTrackingLabel,
    opt_value: outcome.data.odds
  });
};
const onRemoveOutcomeFromBetslip = outcome => {
  svs.components.analytics.trackEvent({
    category: 'Remove from betslip',
    action: buildTrackingActionFromOutcome(outcome),
    opt_label: oddsetTrackingLabel
  });
};
setGlobal('svs.components.oddset.analytics', {
  onBetReceiptAction,
  onClearBetslip,
  onAddOutcomeToBetslip,
  onRemoveOutcomeFromBetslip
});

 })(window);