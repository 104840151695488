(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/oddset/analytics/assets/javascripts/shape-transaction.js') >= 0) return;  svs.modules.push('/components/oddset/analytics/assets/javascripts/shape-transaction.js');
"use strict";


const sbLogger = new svs.core.log.Logger('modules:oddset:ShapeClient');
const {
  transaction: Transaction,
  transactionItem: TransactionItem,
  trackTransaction
} = svs.components.analytics;
const shapeTransaction = _ref => {
  let {
    bets = []
  } = _ref;
  if (bets.length !== 0) {
    bets.forEach(bet => {
      const totalCost = bet.stake;
      const numSelections = bet.events.length;
      const costPerSelection = totalCost / numSelections;
      const betEvents = bet.events.map(event => new TransactionItem('EXTOX', 'Oddset', costPerSelection, 1, {
        betType: bet.betType,
        numberOfEventsInBet: bet.numberOfEventsInBet,
        stakeSetThroughInput: bet.stakeSetThroughInput,
        predefinedStake: bet.predefinedStake,
        eventId: event.eventId,
        eventStartTime: event.eventStartTime,
        eventName: event.eventName,
        isLive: event.isLive,
        region: event.region,
        tournamentName: event.tournamentName,
        sport: event.sport,
        market: event.market
      }));
      const transaction = new Transaction(bet.transactionId, totalCost, betEvents, 0, 0, undefined, 'Oddset');
      trackTransaction(transaction);
    });
  } else {
    sbLogger.log('NO ANALYTICS DATA TO PROCESS. Data:', bets);
  }
};
setGlobal('svs.components.oddset.analytics', {
  shapeTransaction
});

 })(window);